// page-top

// $(function(){
//   var topBtn=$('#page-top');
//   // topBtn.hide();
//   // $(window).scroll(function(){
//   //   if($(this).scrollTop()>80){
//   //     topBtn.fadeIn();
//   //   } else {
//   //     topBtn.fadeOut();
//   //   }
//   // });
//   topBtn.click(function(){
//     $('body,html').animate({
//     scrollTop: 0},500);
//     return false;
//   });
// });


// home header

// $(function(){
//   var header = $('.header');
//   $(window).scroll(function(){
//     if($(this).scrollTop()>300){
//       header.addClass('scrolled');
//     } else {
//       header.removeClass('scrolled');
//     }
//   });
// });


// header-nav

// $('.header-nav__item').click(function () {
//   $(this).toggleClass('opened');
//   $(this).find('a').toggleClass('opened');
//   $(this).find('.header-nav__sub-list').toggleClass('opened');
// });


// sp login

// $('.header-login__btn').click(function () {
//   $('.header-sp-menu').removeClass('opened');
//   $('.header-sp-menu-box').removeClass('opened');
//   if ($('.header-login__btn').hasClass('opened')) {
//     $(this).removeClass('opened');
//     $('.header-login__box').removeClass('opened');
//     $('.overlay').removeClass('showed');
//   } else {
//     $(this).addClass('opened');
//     $('.header-login__box').addClass('opened');
//     $('.overlay').addClass('showed');
//   }
// });


// sp menu

$('.header-sp-menu').click(function () {
  // $('.header-login__btn').removeClass('opened');
  // $('.header-login__box').removeClass('opened');
  if ($('.header-sp-menu').hasClass('opened')) {
    $(this).removeClass('opened');
    $('.header-sp-menu-box').removeClass('opened');
    $('.header-sp-menu__btn').removeClass('opened');
    $('.header-contact').addClass('showed');
    $('.overlay').removeClass('showed');
    $('.footer').removeClass('show-contact');
  } else {
    $(this).addClass('opened');
    $('.header-sp-menu-box').addClass('opened');
    $('.header-sp-menu__btn').addClass('opened');
    $('.header-contact').removeClass('showed');
    $('.overlay').addClass('showed');
    $('.footer').removeClass('show-contact');
  }
});


// header 固定

$(function(){
  var headerNav=$('.header');
  $(window).scroll(function(){
    if($(this).scrollTop()>1){
      headerNav.addClass('fixed');
      $('body').addClass('header-fixed');
    } else {
      headerNav.removeClass('fixed');
      $('body').removeClass('header-fixed');
    }
  });
});


// sp ページ下部お問い合わせ close

$('.header-contact-close').click(function () {
  if ($('.header-contact').hasClass('showed')) {
    $('.header-contact').removeClass('showed');
    $('.footer').removeClass('show-contact');
  } else {
    $('.header-contact').removeClass('showed');
    $('.footer').removeClass('show-contact');
  }
});


// $(function(){
//   $('.move-box .move-item').addClass('moved');
//   $(window).scroll(function(){
//     $('.move-box').each(function(){
//       var imgPos = $(this).offset().top;
//       var scroll = $(window).scrollTop();
//       var windowHeight = $(window).height();
//       if (scroll > imgPos - windowHeight + windowHeight/5){
//         $(this).find('.move-item').removeClass('moved');
//       } else {
//         $(this).find('.move-item').addClass('moved');
//       }
//     });
//   });
// });

$('.overlay').click(function () {
  // $('.header-login__btn').removeClass('opened');
  // $('.header-login__box').removeClass('opened');
  $('.header-sp-menu').removeClass('opened');
  $('.header-sp-menu-box').removeClass('opened');
  $('.header-sp-menu__btn').removeClass('opened');
  $('.header-contact').addClass('showed');
  $('.overlay').removeClass('showed');
});


// img 切り替え

$(function () {
 var $elem = $('.switch');
 var sp = '-sp.';
 var pc = '-pc.';
 var replaceWidth = 990; //ブレイクポイント設定

 function imageSwitch() {
  var windowWidth = parseInt($(window).width());
  $elem.each(function () {
    var $this = $(this);
    if (windowWidth >= replaceWidth) {
      $this.attr('src', $this.attr('src').replace(sp, pc));
    } else {
      $this.attr('src', $this.attr('src').replace(pc, sp));
    }
  });
 }
 imageSwitch();

 var delayStart;
 var delayTime = 200; //ミリSec
 $(window).on('resize', function () {
  clearTimeout(delayStart);
 delayStart = setTimeout(function () {
 imageSwitch();
 }, delayTime);
 });
});


// img hover 切り替え

$(function(){
     $('a img').hover(function(){
        $(this).attr('src', $(this).attr('src').replace('-off', '-on'));
          }, function(){
             if (!$(this).hasClass('currentPage')) {
             $(this).attr('src', $(this).attr('src').replace('-on', '-off'));
        }
   });
});
